@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
    font-family: 'Josefin Sans', sans-serif;
}

.top_bar {
    min-height: 2.33333rem;
    background: #0ac391 !important;
}

.top_bar .top_bar--info li p {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    color: rgb(255 255 255);
}

.top_bar .top_bar--info li span {
    margin-right: 8px;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    color: rgb(255 255 255);
}

.top_bar.top--bar2 .top_bar--social ul li a {
    padding: 5px 5px;
    color: rgb(255 255 255);
    border: 1px solid #fff;
    border-radius: 100px;
    font-size: 14px;
    width: 30px;
    height: 30px;
    display: flex;
    line-height: 15px;
    align-items: center;
    justify-content: center;
}

.menu_area .navbar-nav .nav-link {
    font-weight: 500;
    text-transform: capitalize;
    padding: 0px 0px 0px 40px;
}

.menu_area .navbar {
    padding: 0rem;
    background: #fff !important;
}

.her-bannerimg {
    background: url(../img/banner-three.jpg) center/cover no-repeat;
    padding: 100px 0px;
    position: relative;
    width: 100%;
    padding-bottom: 150px;
}

.her-bannerimg img {
    width: 100%;
}

.her-bannerimg h1 {
    text-align: left;
    font-size: 48px;
    font-weight: bold;
}

.her-bannerimg h6 {
    font-size: 18px;
    font-weight: 400;
    color: #4a4a4a;
    text-align: left;
}

.dropdown .dropdown-menu {
    min-width: 14.66667rem;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #1e2125;
    background-color: #fff;
}

.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    background-color: #ffffff !important;
}

.talkus {
    background: #0ac391;
    color: #fff !important;
    padding: 10px 35px;
    border-radius: 5px;
    font-size: 16px;
    float: left;
    border: none;
}

.threebox-sec {
    margin-top: -100px;
}

.tm-box-col-wrapper {
    margin-bottom: 30px;
}

.themetechmount-post-item {
    margin: 15px 0;
    position: relative;
}

.themetechmount-post-item-inner {
    position: relative;
}

.themetechmount-post-item-inner {
    overflow: hidden;
}

.tm-featured-wrapper {
    position: relative;
}

.themetechmount-box-bottom-content {
    background-color: #fff;
    padding: 15px 10px 15px;
    margin: -55px 20px 0;
    text-align: center;
    border-bottom: 6px solid #413aa4;
    position: relative;
    transition: all .4s ease-in-out;
    box-shadow: 0px 3px 8px #00000038;
    min-height: 240px;
}

.themetechmount-service-box-view-style-one .themetechmount-box-bottom-content {
    border-bottom-color: #2d4a8a;
}

.wp-post-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.themetechmount-box-bottom-content .themetechmount-box-title h4 {
    text-align: center !important;
    font-size: 19px;
    margin-bottom: 0;
    line-height: 26px;
    text-align: left;
}

.themetechmount-box-bottom-content .themetechmount-box-title h4 a {
    color: #000f58;
    text-align: center;
}

.themetechmount-box-view-style-one:hover .themetechmount-box-bottom-content .themetechmount-box-title h4 a {
    color: #fff;
}

.themetechmount-service-box-view-style-one:hover .themetechmount-box-desc {
    color: rgba(255, 255, 255, .95);
}

.themetechmount-service-box-view-style-one:hover .themetechmount-serviceboxbox-readmore a {
    color: #fff;
}

.themetechmount-box-desc {
    font-size: 14px;
    padding-top: 11px;
    color: #292929;
}

.themetechmount-serviceboxbox-readmore {
    padding-top: 9px;
    padding-bottom: 2px;
}

.themetechmount-serviceboxbox-readmore a {
    display: inline-block;
    position: relative;
    color: #0ac391;
    font-size: 14px;
    font-weight: 700;
}

.themetechmount-service-box-view-style-one:hover .themetechmount-box-bottom-content {
    background-color: #2d4a8a;
}

.themetechmount-service-box-view-style-one .themetechmount-box-bottom-content {
    border-bottom-color: #2d4a8a;
}


.midel-newsec {
    position: relative;
    padding: 100px 0px;
    padding-top: 50px;
}

.midel-newsec:after {
    background: url(../img/col-bg-one.jpg) center/cover no-repeat;
    content: "";
    left: 0px;
    width: 90%;
    display: block;
    position: relative;
    height: 670px;
}

.midel-newsec .row {
    margin: 0px;
}

.justify-content-right {
    justify-content: right;
}

.blue-midel-box {
    position: absolute;
    right: 0px;
    z-index: 1;
    background: #0f2f82;
    padding: 50px;
    top: 60px;
    width: 100%;
}

.blue-midel-box h1 {
    color: #fff;
    font-weight: bold;
    font-size: 36px;
}

.lispoint {
    display: flex;
    color: #fff;
    margin-bottom: 35px;
    position: relative;
}

.lispoint:last-child {
    margin-bottom: 0px;
}

.lispoint span {
    font-size: 22px;
    text-align: left;
    margin-right: 20px;
}

.rigcontent h4 {
    color: #fff;
    margin-bottom: 10px;
    line-height: 30px;
    font-size: 24px;
}

.rigcontent p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0px;
}

.shape-heaxgaonbg {
    background: url(../img/process-bg.png) center/cover no-repeat;
    height: 350px;
    text-align: center;
    padding: 130px 0px;
    margin-top: -100px;
}

.shape-heaxgaonbg h2 {
    color: #fff;
    font-size: 48px;
}

.greenshape-bg {
    background: url(../img/green-shape-bg.jpg) center/cover no-repeat;
    text-align: center;
    padding: 60px;
    margin-top: -100px;
    color: #fff;
    padding-bottom: 0px;
}

.greenshape-bg p {
    margin-bottom: 35px;
    font-size: 15px;
    line-height: 24px;
    padding: 0px 15px;
}

.greenshape-bg-shape {
    margin-bottom: 50px;
}

footer .footer__big .widget ul.links li {
    margin-bottom: 0px;
    color: #000;
    display: inline-block;
    padding: 0px 15px;
}

.widget__social .social {
    text-align: right;
}

.widget__social .social ul li {
    margin-right: 0.66667rem;
    display: inline-block;
}

footer .footer__big {
    padding: 3.33333rem 0 0rem;
}

footer .footer__big .widget {
    margin-bottom: 0rem;
}

footer .footer__small p {
    color: rgb(0 0 0);
    margin: 0;
    padding: 0px 0;
}

footer.footer5.footer--bw .footer__big {
    background: #ffffff;
}

footer.footer5.footer--bw .footer__big .widget ul.links a {
    color: rgb(0 0 0);
}

footer .footer__small {
    background: #fff;
}

footer .footer__big .text_widget .footer_logo {
    margin-bottom: 0rem;
    width: 80px;
}

.social ul li a {
    line-height: 2.53333rem;
    width: 35px;
    display: inline-block;
    text-align: center;
    -webkit-border-radius: 33.33333rem;
    border-radius: 100px;
    background: #ffffff;
    color: #2c379e;
    border: 2px solid #2c379e;
    height: 35px;
    line-height: 35px;
}

footer {
    background: #fff;
    position: relative;
}

.globalimg:before {
    background: url(../img/global2.png) center/cover no-repeat;
    content: "";
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    height: 100%;
    overflow: hidden;
    background-position: 60px 0px;
    background-size: cover;
    opacity: 0.5;
}

@media (min-width: 992px) {
    .menu_area .navbar .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
        top: 2.66667rem !important;
    }
}

@media (max-width: 991px) {

    .menu_area .dropdown-menu,
    .menu_area .mega-menu {
        padding: 10px 20px !important;
    }

    .intro-four {
        min-height: 40.33333rem;
    }

    .employeePage {
        margin-left: 0px !important;
    }

    footer.footer5.footer--bw .footer__big {
        background: #ffffff;
        padding-bottom: 0px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        font-size: 26px;
    }

    .icon-box--six .icon-box-four {
        margin-bottom: 1.33333rem;
    }

    .bg_image_holder {
        background-position: 80% 46% !important;
    }

    .intro-four:before {
        width: 60%;
    }

    .default-section {
        padding: 70px 0 40px;
    }

    .wp-post-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}

@media (min-width: 768px) and (max-width: 1080px) {
    .slider-section .carousel-item {
        height: 50vh;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .midel-newsec:after {
        display: none;
    }

    .blue-midel-box {
        position: relative;
        right: 0px;
        z-index: 1;
        background: #0f2f82;
        padding: 50px;
        top: 0px;
        width: 100%;
        margin-bottom: 40px;
    }

    .shape-heaxgaonbg {
        background: url(../img/process-bg.png) center/cover no-repeat;
        height: 290px;
        text-align: center;
        padding: 50px 0px;
        margin-top: -70px;
    }

    .greenshape-bg p {
        margin-bottom: 35px;
        font-size: 14px;
        line-height: 24px;
        padding: 0px 0px;
    }

    footer .footer__big .widget ul.links li {
        margin-bottom: 0px;
        color: #000;
        display: inline-block;
        padding: 0px 10px;
    }

    footer .footer__big .widget {
        margin-bottom: 0rem;
        text-align: center;
    }
}


@media (min-width: 320px) and (max-width: 767px) {
    .her-bannerimg h1 {
        font-size: 30px;
        margin-bottom: 15px !important;
    }

    .her-bannerimg h6 {
        font-size: 14px;
        margin-bottom: 30px !important;
    }

    .her-bannerimg {
        padding: 50px 0px;
        padding-bottom: 120px;
    }

    .her-bannerimg img {
        width: 80%;
        display: block;
        margin: 0px auto;
        margin-top: 50px;
    }

    .midel-newsec:after {
        display: none;
    }

    .midel-newsec {
        position: relative;
        padding: 50px 0px;
        padding-top: 20px;
    }

    .blue-midel-box {
        position: relative;
        right: 0px;
        z-index: 1;
        background: #0f2f82;
        padding: 25px;
        top: 0px;
        width: 100%;
    }

    .rigcontent h4 {
        color: #fff;
        margin-bottom: 10px;
        line-height: 26px;
        font-size: 18px;
    }

    .shape-heaxgaonbg {
        height: 300px;
        padding: 80px 0px;
        margin-top: -100px;
    }

    .shape-heaxgaonbg {
        height: 240px;
        text-align: center;
        padding: 30px 0px;
        margin-top: 0px;
    }

    .greenshape-bg {
        padding: 30px 15px;
        margin-top: -130px;
        color: #fff;
        padding-bottom: 0px;
    }

    .globalimg:before {
        display: none;
    }

    footer .footer__big .widget {
        margin-bottom: 2rem;
        text-align: center;
    }

    footer .footer__big .widget .widget__social {
        margin-top: 0px;
        text-align: center;
    }

    .widget__social .social {
        text-align: center;
    }

    footer .footer__big {
        padding: 0.33333rem 0 2rem;
    }

    .threebox-sec {
        margin-top: 20px;
    }


}



/* Slider */
.carousel-item {
    height: 80vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.carousel-caption {
    position: relative;
    right: 0%;
    bottom: 0px;
    left: 0%;
    z-index: 10;
    padding-top: 0px;
    padding-bottom: 0px;
    color: #fff;
    text-align: center;
    height: 100%;
    width: 90%;
    margin: 0px auto;
    top: 0%;
    display: block;
}

.carousel-caption .h100 {
    height: 100%;
}

.carousel-caption h3 {
    font-size: 22px;
    color: #fff;
    text-align: left;
    font-weight: bold;
    margin-bottom: 10px;
}

.carousel-caption h3 span {
    color: 00c18d;
}

.carousel-caption h5 {
    font-size: 38px;
    color: #fff;
    text-align: left;
    font-weight: 400;
}

.carousel-caption p {
    font-size: 16px;
    font-weight: 300;
    color: #fff;
    text-align: left;
    padding-right: 80px;
    line-height: 23px;
}

.menu_area {
    z-index: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #00000082;
    -webkit-background-size: 100% 100%;
    background-size: 49% 100%;
    padding: 15px;
    border-radius: 4px;
}

.signgup {
    background: #ffffff;
    color: #0bc190 !important;
    padding: 10px 35px;
    border-radius: 5px;
    font-size: 16px;
    float: left;
    margin-left: 10px;
}

.bnplPage {
    background: transparent;
    color: #413aa4;
    padding: 0px 0px;
    border-radius: 0px;
    font-size: 16px;
    float: left;
    margin-left: 0px;
    margin-top: 10px;
    padding-right: 20px;
    border-right: 1px solid #a9a9a9;
}

.employeePage {
    background: transparent;
    color: #413aa4;
    padding: 0px 0px;
    border-radius: 5px;
    font-size: 16px;
    float: left;
    margin-left: 20px;
    margin-top: 10px;
}

.brNone {
    border-right: none;
}

.nav-item {
    flex-wrap: wrap;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

.modal-header {
    background: #fff;
    padding-bottom: 10px;
}

.modal-center .close {
    font-size: 30px;
    height: auto;
    line-height: normal;
}

.modal-center .formbox .btn {
    padding: 10px 30px;
    font-size: 14px;
    box-shadow: none;
    border: none;
}

@media (min-width: 320px) and (max-width: 767px) {
    .carousel-item {
        height: 100vw;
        background-repeat: no-repeat;
        background: 30% 30%;
        background-size: auto;
    }

    .carousel-caption h3 {
        font-size: 24px;
        color: #202428;
    }

    .carousel-caption h3 span {
        color: 00c18d;
    }

    .carousel-caption h5 {
        font-size: 18px;
        color: #202428;
    }

    .carousel-caption p {
        font-size: 14px;
        color: #202428;
        ;
    }

    .carousel-caption img.sliderimg {
        width: 50%;
        margin: 0px auto;
        display: block;
    }

    .carousel-caption img.sliderimg1 {
        width: 80%;
        margin: 0px auto;
        display: block;
    }

    .top_bar {
        min-height: 3.33333rem;
        background: #0ac391 !important;
    }

    .carousel-caption p {
        padding-right: 0px;
    }

    .signgup {
        background: #4239a4;
        color: #ffffff !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        top: -50px;
    }

}


@media (min-width: 768px) and (max-width: 991px) {
    .carousel-item {
        height: 50vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    .carousel-caption h3 {
        font-size: 28px;
    }

    .carousel-caption p {
        font-size: 14px;
    }

    .carousel-caption h5 {
        font-size: 20px;
    }

    .threebox-sec {
        margin-top: -10px;
    }

    .NewFromBox {
        padding: 60px 40px;
        padding-right: 40px;
    }

}

@media (min-width: 992px) and (max-width: 1280px) {
    .carousel-item {
        height: 50vh;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
}

@media (min-width: 1680px) and (max-width: 2000px) {
    .blue-midel-box {
        position: absolute;
        right: 0px;
        z-index: 1;
        background: #0f2f82;
        padding: 100px;
        top: 60px;
        width: 100%;
    }

    .carousel-item {
        height: 50vh;
    }
}

@media (min-width: 1500px) and (max-width: 1650px) {
    .blue-midel-box {
        position: absolute;
        right: 0px;
        z-index: 1;
        background: #0f2f82;
        padding: 70px;
        top: 60px;
        width: 100%;
    }

    .carousel-item {
        height: 60vh;
    }
}

@media (min-width: 1399px) and (max-width: 1499px) {
    .carousel-item {
        height: 60vh;
    }
}

@media (min-width: 992px) and (max-width: 1080px) {
    .blue-midel-box {
        padding: 40px;
        top: 30px;
    }
}

.w-65-ogo {
    width: 150px;
    object-fit: contain;
    height: auto;
}

a {
    color: #0d6efd;
    text-decoration: none;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 1.2rem;
    font-size: 14px;
    line-height: 30px;
}

.form-control:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #413aa4;
    outline: 0;
    box-shadow: none;
}

.hr {
    margin: 5.33333rem 0;
}

.posRealtive {
    position: relative;
}

.fweight {
    font-weight: 900;
    font-size: 54px;
}

.NewFromBox {
    padding: 60px 10px;
    padding-right: 40px;
}

.HeadingEWABox h6 {
    font-size: 24px;
    color: #0ac391;
}

.mideelHeadigBox h6 {
    margin: 0 0 1.13333rem 0;
    color: #4239a4;
    font-size: 26px;
    font-weight: 700;
    min-height: 70px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.EWAPage .accordion_four .accordion-heading h6 a {
    background: #e7e7e7;
}

.EWAPage .accordion_four .accordion-heading h6 a[aria-expanded="true"] {
    background: #06b590;
    color: #fff;
}

@media (min-width: 768px) and (max-width: 991px) {
    .NewFromBox {
        padding: 60px 40px;
        padding-right: 40px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .dMobileNone {
        display: none;
    }

    .wp-post-image {
        height: 260px;
    }

    .fweight {
        font-weight: 900;
        font-size: 36px;
    }

    .NewFromBox {
        padding: 30px 15px;
        padding-right: 15px;
    }
}






.heroBnnerImgNew {
    padding: 130px 0px;
    height: 660px;
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.tWhiteSmall {
    color: #fff;
    font-size: 18x;
    margin-bottom: 20px;
}

.whiteBigHeadTxt {
    color: #fff;
    font-size: 40px;
    margin-bottom: 20px;
}

.middleWhiteSubTxt {
    color: #fff;
    font-size: 26px;
    margin-bottom: 40px;
}

.btnFlexNewHero {
    display: flex;
    gap: 10px;
}

.newBtnHeroImg {
    background-color: #fff;
    color: #4239A4;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
}

.newBtnHeroImg i {
    transform: rotate(322deg);
}

.whyChooseeUsNew {
    padding: 80px 0px;
    position: relative;
    background-position: center;
    background-size: cover;
}

.whyChooeNewHead {
    color: #4239A4;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.whyChoosePraNew {
    text-align: center;
    color: #292929;
    font-size: 16px;
    line-height: 20px;
}

.whyChooseBorD {
    border: 1px solid #E0EBFF;
    padding: 20px;
    border-radius: 14px;
    max-height: 140px;
    min-height: 140px;
    height: 140px;
    margin-bottom: 30px;
}

.whyChooseBorD h4 {
    display: flex;
    gap: 15px;
    color: #4239A4;
    font-size: 16px;
    align-items: center;
    margin-bottom: 10px;
}

.whyChooseBorD p {
    color: #737375;
    font-size: 13px;
    margin-bottom: 0px;
    line-height: 20px;
}


.workNewWithUs {
    padding: 80px 0px;
    background-color: #0AC391;
    position: relative;
}

.workWithUsNewHead {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.workWithUsNewPra {
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: 20px;
}

.workLogoWithUs {
    border: 1px solid #D5D9DD;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 20px;
}

.workLogoWithUs img {
    object-fit: contain;
    width: 100%;
    height: 60px;
}

.joinUsBtnNew {
    background-color: #4239A4;
    color: #fff;
    padding: 15px 20px;
    color: #fff;
    border-radius: 4px;
    width: 160px;
    display: block;
    text-align: center;
    margin: 0px auto;
}

.dropdown-toggle::after {
    display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
    .whyChooseBorD {
        max-height: auto;
        min-height: auto;
        height: auto;
    }
}

